'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.dispatch.factory:DispatchUnitsManager

 # @description

###
angular
  .module 'mundoAdmin.dispatch'
  .factory 'DispatchUnitsManager', [
    'MundoDefaultManager'
    'DispatchGroupsManager'
    'DispatchUnitTypesManager'
    'DispatchUnitStatusesManager'
    'UnitsManager'
    '$log'
    '$state'
    '$stateParams'
    '$rootScope'
    '$timeout'
    '$sce'
    '$mdDialog'
    '$window'
    (
      MundoDefaultManager
      DispatchGroupsManager
      DispatchUnitTypesManager
      DispatchUnitStatusesManager
      UnitsManager
      $log
      $state
      $stateParams
      $rootScope
      $timeout
      $sce
      $mdDialog
      $window
    ) ->
      DispatchUnitsManagerBase = new MundoDefaultManager()
      DispatchUnitsManagerBase.setUrl('lpa/dispatch/units')
      DispatchUnitsManagerBase.setNewObject(
        [
          'closedAt'
          'label'
          'dispatchGroup'
          'dispatchUnitType'
          'dispatchUnitStatus'
          'unit'
        ]
      )

      DispatchUnitsManagerBase.setUpdateObject(
        [
          'closedAt'
          'label'
          'dispatchGroup'
          'dispatchUnitType'
          'dispatchUnitStatus'
          'unit'
        ]
      )

      DispatchUnitsManagerBase.setSearchFields(
        [
          'label'
          'unit.label'
          'dispatchUnitStatus.label'
          'createdAt'
          'closedAt'
          'dispatchGroup.label'
        ]
      )


      DispatchUnitsManagerBase.new = (data) ->
        if (data.closedAt)
          data.closedAt = moment(data.closedAt).format('YYYY-MM-DD')

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()))

      DispatchUnitsManagerBase.update = (id, data) ->
        if (data.closedAt)
          data.closedAt = moment(data.closedAt).format('YYYY-MM-DD')

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      DispatchUnitsManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'dispatchGroup'
          name: 'dispatchGroup'
          type: 'select'
          templateOptions:
            label: 'Group'
            placeholder: 'Group'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DispatchGroupsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'dispatchUnitType'
          name: 'dispatchUnitType'
          type: 'select'
          templateOptions:
            label: 'Type'
            placeholder: 'Type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DispatchUnitTypesManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'dispatchUnitStatus'
          name: 'dispatchUnitStatus'
          type: 'select'
          templateOptions:
            label: 'Status'
            placeholder: 'Status'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DispatchUnitStatusesManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'unit'
          name: 'unit'
          type: 'select'
          templateOptions:
            label: 'Unit'
            placeholder: 'Unit'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UnitsManager.getFullList({'sort' : 'label,ASC'}).$object
        ]

      DispatchUnitsManagerBase.getEditForm = (data) ->
        closedAt = data.closedAt

        if closedAt
          closedAt = moment(data.closedAt).toDate()

        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
        ,
          key: 'dispatchGroup'
          name: 'dispatchGroup'
          type: 'select'
          defaultValue: data.dispatchGroup.id
          templateOptions:
            label: 'Group'
            placeholder: 'Group'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DispatchGroupsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'dispatchUnitType'
          name: 'dispatchUnitType'
          type: 'select'
          defaultValue: data.dispatchUnitType.id
          templateOptions:
            label: 'Type'
            placeholder: 'Type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DispatchUnitTypesManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'dispatchUnitStatus'
          name: 'dispatchUnitStatus'
          type: 'select'
          defaultValue: data.dispatchUnitStatus.id
          templateOptions:
            label: 'Status'
            placeholder: 'Status'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DispatchUnitStatusesManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'unit'
          name: 'unit'
          type: 'select'
          defaultValue: data.unit.id
          templateOptions:
            label: 'Unit'
            placeholder: 'Unit'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UnitsManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'closedAt'
          name: 'closedAt'
          type: 'datepicker'
          defaultValue : closedAt
          templateOptions:
            label: 'closedAt'
            placeholder: 'closedAt'
            required: false
        ]
      DispatchUnitsManagerBase.getListSchema = =>
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'dispatchUnitStatus.label'
          title: 'datatable.label.status'
  #        sort: 'dispatchUnitStatus.label'
        ,
          key: 'dispatchGroup.label'
          title: 'datatable.label.group'
  #        sort: 'dispatchGroup.label'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
          hideInList: false
        ,
          key: 'closedAt'
          title: 'datatable.label.closed.at'
          sort: 'closedAt'
          type: 'dateTime'
          hideInList: !@showClosedUnits
        ]

      DispatchUnitsManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/dispatch/views/dispatch-units-detail.tpl.html'

      DispatchUnitsManagerBase.getSchemaLinks = ->
        [
          key: 'unit.label'
          title: 'Asset'
          action: (row) ->
            assetLabel = row.unit.label
            $state.go('assets.assets').then(->
              $timeout(->
                $rootScope.$broadcast('searchFor',
                  {term: assetLabel, to: 'app.admin.pageTitles.assets.assets'})
              ,100)
            )
          ,
            key: 'contact.label'
            title: 'datatable.label.contacts'
            multiple: 'contacts'
            hideInList: true
            action: (part) ->
              contactlabel = part.contact.label
              $state.go('contacts.overview').then(->
                $timeout(->
                  $rootScope.$broadcast('searchFor',
                    {term: contactlabel, to: 'app.admin.pageTitles.contacts.contacts'})
                ,100)
              )
        ]


      #rename this function
      DispatchUnitsManagerBase.getOne = DispatchUnitsManagerBase.one

      #add the contact list to the unit object
      DispatchUnitsManagerBase.one = (id) ->
        DispatchUnitsManagerBase.getOne(id)
          .then((result)=>
            @getContacts(result.id)
              .then((contacts) ->
                result.contacts = contacts.results
                return result
              )
          )

      DispatchUnitsManagerBase.getContacts = (id) ->
        urn = "lpa/dispatch/units/#{id}/contacts"
        @transport.one(urn).get()

      DispatchUnitsManagerBase.addContact = (id, data) ->
        urn = "lpa/dispatch/units/#{id}"
        @transport.one(urn).all('contacts').post(data)

      DispatchUnitsManagerBase.deleteContact = (id, contactId) ->
        urn = "lpa/dispatch/units"
        @transport.one(urn,id).one('contacts', contactId).remove()

      editContacts =
        [
          icon: 'perm_identity'
          text: 'datatable.tooltips.editContacts'
          action: (id, smthg) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/dispatch/views/dispatch-unit-contacts.modal.tpl.html'
              controller: 'DispatchUnitContactsCtrl'
              controllerAs: 'ctrl'
              locals:
                id: id
        ]

      DispatchUnitsManagerBase.addExtraAction(editContacts)

      unselectedIcon = 'add_circle_outline'
      unselectedText = 'show all units'

      selectedIcon = 'remove_circle_outline'
      selectedText = 'show only active units'

      filterActiveUnits =
        [
          icon: unselectedIcon
          text: unselectedText
          action: (ctrl) ->
            if @.icon == selectedIcon
              @.icon = unselectedIcon
              @.text = unselectedText
            else
              @.icon = selectedIcon
              @.text = selectedText

            show = DispatchUnitsManagerBase.activeUserFilter()
            # reload the items in the list
            $state.$current.locals['units@dispatch'].$scope.listCtrl.load()
            $state.$current.locals['units@dispatch'].$scope.listCtrl.schema[4].hideInList = !show
        ]

      DispatchUnitsManagerBase.getListDefault = DispatchUnitsManagerBase.getList

      DispatchUnitsManagerBase.getList = (query, loadFilterParams) =>
        if @showClosedUnits
          loadFilterParams = {}
        else
          loadFilterParams =
            {
              'filter[closedAt]': 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()
            }
        DispatchUnitsManagerBase.getListDefault(query, loadFilterParams)

      DispatchUnitsManagerBase.activeUserFilter = () =>
        @showClosedUnits = ! @showClosedUnits
        @showClosedUnits

      DispatchUnitsManagerBase.addTableAction(filterActiveUnits)

      DispatchUnitsManagerBase.editPermission = 'manage all MundoMosaLpaDispatchingBundle:DispatchUnit entities'

      DispatchUnitsManagerBase.showClosedUnits = false

      DispatchUnitsManagerBase
  ]
